import { useStore, computed } from '@nuxtjs/composition-api'
import { useTime } from './useTime'
import { useStore as useShop } from './useStore'
import type { Store } from '~/types/store'
import { ProductGetters } from '~/store/product'
import type {
  StockObject,
  StoreAvailabilityInfo,
  StoreAvailabilityInfoSummary,
  EcomAvailabilityInfo,
  UnitObjectValue,
  StoreStockStatus,
  ProductAlgolia
} from '~/types/product'

export function useStock () {
  const store = useStore()
  const { stores } = useShop()

  //
  // Ecommerce stock
  //
  const getEcomAvailabilityInfo = (stock: StockObject, productDeliverTime?: UnitObjectValue<string>): EcomAvailabilityInfo => {
    if (stock === undefined || stock === null || Array.isArray(stock) || !stock.stockStatus) {
      return defaultEcomStock
    }
    return {
      stockStatus: stock.stockStatus,
      availabilityText: getEcomAvailabilityText(stock),
      deliveryPhrase: getDeliveryEstimationPhrase(stock, productDeliverTime),
      qty: stock.qty,
      cssClasses: getStatusCssClasses(stock.stockStatus),
      icon: getStatusIcon(stock.stockStatus)
    }
  }

  //
  // Physical store stock
  //
  const getStoreAvailabilityInfo = (stockObject: StockObject): StoreAvailabilityInfo => {
    const stock: StockObject = { ...stockObject }
    if (stock === undefined || stock === null || Array.isArray(stock) || !stock.stockStatus) {
      return getDefaultStoreStock(stores)
    }

    return stores.reduce((availabilities, store): StoreAvailabilityInfo => ({
      ...availabilities,
      [store.code]: {
        onDisplay: !!stock.storeAvailability?.[store.code]?.onDisplayStock || false,
        storeStock: stock.storeAvailability?.[store.code]?.storeStock || 0,
        stockStatus: getStoreStatus(stock, store.code),
        availabilityText: getStoreAvailabilityText(stock, store.code)
      }
    }), {})
  }

  //
  // Physical store stock summary for the current product
  //
  const getStoreAvailabilityInfoSummary = computed(() => {
    const currentProduct: ProductAlgolia = store.getters[ProductGetters.currentProduct]
    const stock: StockObject = { ...currentProduct['stock'] }
    const defaultSummary: StoreAvailabilityInfoSummary = {
      ecomStockStatus: stock?.stockStatus || 'outOfStock',
      onDisplayStoreCodes: [],
      inStockStoreCodes: [],
      variationsOnDisplayStoreCodes: []
    }

    if (stock === undefined || stock === null || Array.isArray(stock) || !stock.stockStatus) {
      return defaultSummary
    }

    const summary = stores.reduce((summary, shop): StoreAvailabilityInfoSummary => {
      if (stock.storeAvailability?.[shop.code]?.storeStock) {
        summary.inStockStoreCodes.push(shop.code)
      }
      if (stock.storeAvailability?.[shop.code]?.onDisplayStock) {
        summary.onDisplayStoreCodes.push(shop.code)
      }
      if (store.getters[ProductGetters.variationsOnDisplay](shop.code).filter((p: ProductAlgolia) => p.sku !== currentProduct.sku).length > 0) {
        summary.variationsOnDisplayStoreCodes.push(shop.code)
      }
      return summary
    }, defaultSummary)

    return summary
  })

  //
  // Physical store availability text for the current product
  //
  const storeAvailabilityText = computed(() => {
    const { ecomStockStatus, inStockStoreCodes, onDisplayStoreCodes, variationsOnDisplayStoreCodes } = getStoreAvailabilityInfoSummary.value

    const codesToString = (codes: string[], from = false): string => {
      if (codes.length === 1) {
        const storeGenetive = stores.find(store => store.code === codes[0])?.label.genitive
        if (storeGenetive) {
          return storeGenetive
        }
      }
      const _in = ['yhdessä', 'kahdessa', 'kolmessa', 'neljässä', 'viidessä', 'kuudessa']
      return from ? _in[codes.length - 1].replace('ss', 'st') : _in[codes.length - 1]
    }

    return inStockStoreCodes.length
      ? `Heti saatavilla ${codesToString(inStockStoreCodes, true)} myymälästä`
      : onDisplayStoreCodes.length
        ? `Esillä ${codesToString(onDisplayStoreCodes)} myymälässä`
        : variationsOnDisplayStoreCodes.length
          ? `Vastaava malli esillä ${codesToString(variationsOnDisplayStoreCodes)} myymälässä`
          : ecomStockStatus !== 'outOfStock' ? 'Tilattavissa myymälästä' : 'Ei saatavilla myymälästä'
  })

  return {
    getEcomAvailabilityInfo,
    getStoreAvailabilityInfo,
    getStoreAvailabilityInfoSummary,
    storeAvailabilityText
  }
}

const { formatISOString } = useTime()

const isAvailableInAnyStoreStock = (stock: StockObject): boolean => {
  return Object.values(stock.storeAvailability).some(store => store.storeStock > 0)
}

/*
 * Returns stock quantity as a string: '3 kpl'
 * When limit is exceeded, returns 'yli {limit} kpl'
 */
const getQtyEstimate = (qty: number, limit: number): string => (qty <= limit) ? `${qty} kpl` : `yli ${limit} kpl`

/*
* Returns stock quantity as a string: '3 kpl'
* When limit is exceeded, returns 'yli {limit} kpl'
*/
const getStoreAvailabilityText = (stock: StockObject, storeCode: string): string => {
  // Varastossa 3 kpl, Varastossa yli 20 kpl, Loppu varastosta, Tilattavissa
  switch (getStoreStatus(stock, storeCode)) {
    case 'inStock':
      return `Varastossa ${getQtyEstimate(stock.storeAvailability?.[storeCode]?.storeStock || 0, 20)}`
    case 'backorder':
      return 'Tilattavissa'
    default:
      return 'Loppu varastosta'
  }
}

/*
* Returns stock status for a store: 'inStock'|'backorder'|'outOfStock'
*/
const getStoreStatus = (stock: StockObject, storeCode: string): StoreStockStatus => {
  return stock.storeAvailability[storeCode] && stock.storeAvailability[storeCode].storeStock > 0 ? 'inStock' : !['openOutOfStock', 'outOfStock'].includes(stock.stockStatus) ? 'backorder' : 'outOfStock'
}

/*
* Returns availability text for e-commerce stock, e.g. 'Varastossa 3 kpl', 'Tulossa varastoon', 'Tilapäisesti loppu'
*/
const getEcomAvailabilityText = (stock: StockObject): string => {
  switch (stock.stockStatus) {
    case 'inStock':
      return `Verkkokaupan varastossa ${getQtyEstimate(stock?.qty || 0, 10)}`
    case 'backorder':
    case 'openBackorder':
      return 'Tulossa verkkokaupan varastoon'
    case 'openOutOfStock':
      return 'Tilapäisesti loppu verkkokaupan varastosta'
    default:
      return 'Loppu verkkokaupan varastosta'
  }
}

/*
* Returns delivery estimation for an openBackorder product, e.g. 'Lähetettävissä n. 15.3.2021', 'Toimitusaika 4-6 viikkoa'
*/
const getDeliveryEstimationPhraseForOpenBackorder = (stock: StockObject, productDeliverTime?: UnitObjectValue<string>): string => {
  const productFallbackDeliveryTime = productDeliverTime?.value
  const nextDeliveryDateStr = stock.nextDeliveryDate
  const nextDeliveryDate = nextDeliveryDateStr ? new Date(nextDeliveryDateStr) : null

  // If stock next delivery date is available, not dummy 2099-11-11 date and is in the future
  if (nextDeliveryDate && nextDeliveryDate.getFullYear() !== 2099 && nextDeliveryDate > new Date()) {
    return `Lähetettävissä n. ${formatISOString(nextDeliveryDateStr!, 'd.M.yyyy', nextDeliveryDate.toString())}`
  }

  return productFallbackDeliveryTime ? `Toimitusaika ${productFallbackDeliveryTime}` : 'Toimitusaika 4-6 viikkoa'
}

/*
* Returns delivery estimation for a product, e.g. 'Toimitusaika 3-6 arkipäivää', 'Lähetettävissä n. 15.3.2021', 'Vain myymälästä'
*/
const getDeliveryEstimationPhrase = (stock: StockObject, productDeliverTime?: UnitObjectValue<string>): string => {
  switch (stock.stockStatus) {
    case 'inStock':
      return 'Toimitusaika 3-6 arkipäivää'
    case 'backorder':
      return `Lähetettävissä n. ${formatISOString(stock.nextDeliveryDate, 'd.M.yyyy', stock.nextDeliveryDate)}`
    case 'openBackorder':
      return getDeliveryEstimationPhraseForOpenBackorder(stock, productDeliverTime)
    case 'openOutOfStock':
      return isAvailableInAnyStoreStock(stock) ? 'Saatavilla myymälästä' : ''
    case 'outOfStock':
      return isAvailableInAnyStoreStock(stock) ? 'Vain myymälästä' : ''
    default:
      return ''
  }
}

const getStatusCssClasses = (stockStatus: StockObject['stockStatus']): EcomAvailabilityInfo['cssClasses'] => {
  switch (stockStatus) {
    case 'inStock':
      return 'text-success'
    case 'backorder':
    case 'openBackorder':
      return 'text-galliano'
    default:
      return 'text-error'
  }
}

const getStatusIcon = (stockStatus: string): EcomAvailabilityInfo['icon'] => {
  switch (stockStatus) {
    case 'inStock':
      return 'check-filled-icon'
    case 'backorder':
    case 'openBackorder':
      return 'clock-filled-icon'
    default:
      return 'x-filled-icon'
  }
}

const defaultEcomStock: EcomAvailabilityInfo = {
  stockStatus: 'outOfStock',
  availabilityText: 'Loppu varastosta',
  deliveryPhrase: '',
  cssClasses: 'text-error',
  icon: 'x-filled-icon',
  qty: 0
}

const getDefaultStoreStock = (stores: Store[]): StoreAvailabilityInfo => {
  return stores.reduce((availabilities, store): StoreAvailabilityInfo => ({
    ...availabilities,
    [store.code]: {
      status: 'outOfStock',
      availabilityText: 'Loppu varastosta',
      onDisplay: false,
      storeStock: 0
    }
  }), {})
}

import { render, staticRenderFns } from "./ProductTile.vue?vue&type=template&id=2fea3257&scoped=true"
import script from "./ProductTile.vue?vue&type=script&lang=ts"
export * from "./ProductTile.vue?vue&type=script&lang=ts"
import style0 from "./ProductTile.vue?vue&type=style&index=0&id=2fea3257&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fea3257",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductLabels: require('/src/nuxt-app/components/product/ProductLabels.vue').default,ProductPriceLabel: require('/src/nuxt-app/components/product/ProductPriceLabel.vue').default})

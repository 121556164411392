
import Vue from 'vue'
import ReviewStars from '~/components/product/ReviewStars.vue'
import StockStatus from '~/components/product/StockStatus.vue'
import type { ProductAlgolia, ProductTileAlgolia } from '~/types/product'
import ProductPriceLabel from '~/components/product/ProductPriceLabel.vue'
import useProduct from '~/mixins/useProduct'
import { SwatchGetters } from '~/store/swatch'
import ProductLabels from '~/components/product/ProductLabels.vue'
import type { SwatchNameWithStyles } from '~/types/swatch'
import { UiGetters } from '~/store/ui'
export default Vue.extend({
  name: 'ProductTile',
  components: {
    ProductPriceLabel,
    ProductLabels,
    ReviewStars,
    StockStatus
  },
  mixins: [useProduct],
  props: {
    product: {
      type: Object as () => ProductTileAlgolia|ProductAlgolia,
      required: true
    }
  },
  data () {
    return {
      maxColors: 5
    }
  },
  computed: {
    milieuImage () {
      return this.$store.getters[UiGetters.isMilieuImageShown]
    },
    colors (): string[] {
      return this.product?.colorVariations?.filter(Boolean) || []
    },
    visibleColors (): SwatchNameWithStyles[] {
      const qty = (this.colors.length > this.maxColors) ? this.maxColors - 1 : this.maxColors
      const visibleColorNames = this.colors.slice(0, qty)
      return this.$store.getters[SwatchGetters.getManySwatchStyles]('Väri', visibleColorNames)
    },
    colorsLeftQty (): number {
      return (this.colors.length > this.maxColors) ? this.colors.length - (this.maxColors - 1) : 0
    },
    reviewScore (): number {
      return this.product.yotpo?.reviewScore || 0
    },
    reviewAmount (): number {
      return this.product.yotpo?.reviewAmount || 0
    }
  }
})


import Vue from 'vue'
import useProduct from '~/mixins/useProduct'
import type { ProductAlgolia, ProductTileAlgolia } from '~/types/product'
export default Vue.extend({
  name: 'ProductPriceLabel',
  mixins: [useProduct],
  props: {
    product: {
      type: Object as () => ProductTileAlgolia|ProductAlgolia,
      required: true
    },
    useStartingPrices: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isOnSale (): boolean {
      return this.useStartingPrices ? this.lowestVariantHasDiscount : !!this.product?.finalPrice?.isOnSale
    }
  }
})


import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'
import type { ProductLabel } from '~/types/product'

export default defineComponent({
  name: 'ProductLabels',
  props: {
    labels: {
      type: Array as PropType<ProductLabel[]>,
      required: false,
      default: () => ([])
    },
    forProductPage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const positionClassMap = {
      'Top left': 'pos-top-left',
      'Top right': 'pos-top-right',
      'Bottom left': 'pos-bottom-left',
      'Bottom right': 'pos-bottom-right'
    }
    const getPositionClasses = (label: ProductLabel) => positionClassMap[label.position] || ''

    // Temporary: Round labels
    const isRound = (label: ProductLabel) => ['7ArSWgPSrdxI0K8d055HVK', '2YIwcrFscjHnz2viNKdQGE'].includes(label.id || '')
    // Temporary: Price labels (filtered out)
    const isPriceLabel = (label: ProductLabel) => ['supertarjous', 'aina edullinen', 'superhinta', 'outlet'].includes(label.text.toLowerCase() || '')

    // Leave max. 1 label per position, sorted by priority
    const filteredLabels = computed(() => props.labels.slice().sort((a, b) => b.priority - a.priority).filter((v, i, a) => a.findIndex(v2 => (v2.position === v.position)) === i && !isPriceLabel(v)))

    const hasLongText = (label: ProductLabel): boolean => !!label.text && label.text.length > 15

    return { getPositionClasses, filteredLabels, isRound, hasLongText }
  }
})


import { defineComponent, computed, PropType, ComputedRef } from '@nuxtjs/composition-api'
import { CheckCircleIcon, XCircleIcon, ClockIcon } from '~/components/veke-ui/icons'
import type { ProductAlgolia, ProductTileAlgolia, ProductTileMiniAlgolia, EcomAvailabilityInfo, UnitObjectValue } from '~/types/product'
import { useStock } from '~/composable/useStock'
import { CartItem } from '~/types/item'

export default defineComponent({
  name: 'StockStatus',
  components: { CheckCircleIcon, XCircleIcon, ClockIcon },
  props: {
    item: {
      type: Object as PropType<ProductTileMiniAlgolia|ProductTileAlgolia|ProductAlgolia|CartItem|null>,
      required: false,
      default: null
    }
  },
  setup (props) {
    const availability: ComputedRef<EcomAvailabilityInfo|null> = computed(() => {
      if (!props.item?.stock) { return null }
      const { getEcomAvailabilityInfo } = useStock()
      return getEcomAvailabilityInfo(props.item?.stock, props.item?.erpinfo?.productDeliverTime as UnitObjectValue<string>)
    })
    return { availability }
  }
})
